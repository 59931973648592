<template>
  <router-link v-if="to" @click="handleClick" :to="to" :style="style" class="ph-link">
    <slot></slot>
  </router-link>
  <a
    v-else
    @click="handleClick"
    :target="blank ? '__blank' : ''"
    :href="href"
    :style="style"
    class="ph-link"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'PhLink',
  props: {
    blank: Boolean,
    clickOnly: Boolean,
    to: [String, Object],
    href: {
      type: String,
      default: '#',
    },
  },
  computed: {
    style() {
      return `--color: ${
        this.$vuetify.theme.isDark
          ? this.$vuetify.theme.themes.dark.accent
          : this.$vuetify.theme.themes.light.accent
      }`;
    },
  },
  methods: {
    handleClick(e) {
      if (this.clickOnly) {
        e.preventDefault();
      }
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.ph-link {
  display: inline-block;
  color: var(--color);
  border-bottom: 1px dashed var(--color);
}
</style>
