<template>
  <div class="error-details" v-if="show">
    <VMessages :color="color" :value="messages"></VMessages>
  </div>
</template>

<script>
import { VMessages } from 'vuetify/lib';

export default {
  name: 'InputDetails',
  components: {
    VMessages,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    color: {
      type: String,
      default: () => 'error',
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.error-details {
  padding: 0 12px;
}
</style>
