<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INSURANCE_FIELD_ALIASES.INSURANCE_COMPANY_NAME, arguments[0])"
          :value="this[INSURANCE_FIELD_ALIASES.INSURANCE_COMPANY_NAME]"
          :rules="['required']"
          label="Insurance company"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(INSURANCE_FIELD_ALIASES.INSURANCE_POLICYHOLDER_NAME, arguments[0])
          "
          :value="this[INSURANCE_FIELD_ALIASES.INSURANCE_POLICYHOLDER_NAME]"
          :rules="['required']"
          label="Policy holder's name"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INSURANCE_FIELD_ALIASES.INSURANCE_POLICY_NUMBER, arguments[0])"
          :value="this[INSURANCE_FIELD_ALIASES.INSURANCE_POLICY_NUMBER]"
          :rules="['required', 'memberIdRule']"
          label="Policy number"
          type="text"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INSURANCE_FIELD_ALIASES.INSURANCE_GROUP_NUMBER, arguments[0])"
          :value="this[INSURANCE_FIELD_ALIASES.INSURANCE_GROUP_NUMBER]"
          :rules="['required', 'memberIdRule']"
          label="Group number"
          type="text"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { getInsuranceInfoFormProps } from '@/utils/helpers/app/claims';

export default {
  name: 'InsuranceInfo',
  mixins: [syncForm],
  props: {
    ...getInsuranceInfoFormProps(),
  },
};
</script>
