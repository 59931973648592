<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(COACH_FIELD_ALIASES.COACH_INJURED_CLUB_NAME, arguments[0])"
          :value="this[COACH_FIELD_ALIASES.COACH_INJURED_CLUB_NAME]"
          :rules="['required']"
          label="Club Name of Injured"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(COACH_FIELD_ALIASES.COACH_INCIDENT_TERRITORY, arguments[0])"
          :value="this[COACH_FIELD_ALIASES.COACH_INCIDENT_TERRITORY]"
          :rules="['required']"
          label="Territory Where Incident Occurred"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <FormSection title="Was athlete treated by Trainer and/or Official at School?">
          <v-radio-group
            @change="
              handleFieldInput(COACH_FIELD_ALIASES.COACH_INJURED_TREATED_BY_OFFICIALS, arguments[0])
            "
            :value="this[COACH_FIELD_ALIASES.COACH_INJURED_TREATED_BY_OFFICIALS]"
            :rules="['requiredBoolean']"
            row
          >
            <v-radio :value="true" label="Yes"></v-radio>
            <v-radio :value="false" label="No"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(COACH_FIELD_ALIASES.COACH_FIRST_NAME, arguments[0])"
          :value="this[COACH_FIELD_ALIASES.COACH_FIRST_NAME]"
          :rules="['required']"
          label="Coach First Name"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(COACH_FIELD_ALIASES.COACH_LAST_NAME, arguments[0])"
          :value="this[COACH_FIELD_ALIASES.COACH_LAST_NAME]"
          :rules="['required']"
          label="Coach Last Name"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(COACH_FIELD_ALIASES.COACH_EMAIL, arguments[0])"
          :value="this[COACH_FIELD_ALIASES.COACH_EMAIL]"
          :rules="['required', 'email']"
          type="email"
          label="Coach Email"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(COACH_FIELD_ALIASES.COACH_PHONE, arguments[0])"
          :value="this[COACH_FIELD_ALIASES.COACH_PHONE]"
          :rules="['phone']"
          type="tel"
          label="Coach Phone"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { getCoachInfoFormProps } from '@/utils/helpers/app/claims';
import FormSection from '@/components/FormSection';

export default {
  name: 'CoachInfo',
  mixins: [syncForm],
  components: {
    FormSection,
  },
  props: {
    ...getCoachInfoFormProps(),
  },
};
</script>
