<template>
  <span class="date-span">
    <span v-if="label">{{ label }} </span>
    <span>{{ formattedDate }}</span>
  </span>
</template>

<script>
import Time from '@/utils/classes/Time';

export default {
  name: 'DateSpan',
  props: {
    date: {
      type: String,
    },
    format: {
      default: 'MM/DD/YYYY',
      // eslint-disable-next-line
      validator: val => typeof val === 'string' && val.length && Time._validateFormat(val),
    },
    label: String,
  },
  computed: {
    formattedDate() {
      if (this.date) {
        return Time.getFormattedDate(this.date, this.format);
      }
      return Time.getFormattedDate(null, this.format);
    },
  },
};
</script>

<style lang="scss" scoped>
.date-span {
  display: inline-block;
  color: $text-light;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}
</style>
