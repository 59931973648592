<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <PhDatepicker
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_DATE, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_DATE]"
          :hideDetails="false"
          placeholder="Date of incident"
          fullWidth
          required
        />
      </v-col>
      <v-col cols="12" md="6">
        <PhTimepicker
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_TIME, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_TIME]"
          :hideDetails="false"
          placeholder="Time of incident"
          fullWidth
          required
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]"
          :rules="['required']"
          label="Location of incident"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Membership type" required>
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_TYPE, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_TYPE]"
            :items="selectOptions.membershipTypes"
            :rules="['required']"
            placeholder="Select"
          />
          <div v-if="isOther(this[INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_TYPE])" class="mt-2">
            <v-textarea
              @input="
                handleFieldInput(
                  INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_OTHER_TYPE,
                  arguments[0]
                )
              "
              :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_OTHER_TYPE]"
              label="Other membership type"
              outlined
            />
          </div>
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection title="Event type" required>
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_TYPE, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_TYPE]"
            :items="selectOptions.eventTypes"
            :rules="['required']"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Contact or Non-contact?" required>
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT]"
            :items="selectOptions.injuryContacts"
            :rules="['requiredBoolean']"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection
          :required="this[INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT]"
          title="Type of contact"
        >
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT_TYPE, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT_TYPE]"
            :items="selectOptions.injuryContactTypes"
            :rules="this[INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT] ? ['required'] : []"
            placeholder="Select"
          />
          <div v-if="isOther(this[INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT_TYPE])" class="mt-2">
            <v-textarea
              @input="
                handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_OTHER_CONTACT_TYPE, arguments[0])
              "
              :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_OTHER_CONTACT_TYPE]"
              label="Other contact type"
              outlined
            />
          </div>
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Body part injured" required>
          <v-select
            @input="
              handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED, arguments[0])
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]"
            :items="selectOptions.bodyParts"
            :rules="['required']"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection title="Body side injured" required>
          <v-select
            @input="
              handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED, arguments[0])
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]"
            :items="selectOptions.bodySides"
            :rules="['required']"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Severity" required>
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_SEVERITY, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_SEVERITY]"
            :items="selectOptions.injurySeverityTypes"
            :rules="['required']"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection title="Type of injury" required>
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]"
            :items="selectOptions.injuryTypes"
            :rules="['required']"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Playing Surface">
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_PLAYING_SURFACE, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_PLAYING_SURFACE]"
            :items="selectOptions.playingSurfaces"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="6">
        <FormSection title="Did you receive onsite care?" required>
          <v-radio-group
            @change="
              handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_ONSITE_CARE_RECEIVED, arguments[0])
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_ONSITE_CARE_RECEIVED]"
            :rules="['requiredBoolean']"
            row
          >
            <v-radio :value="true" label="Yes"></v-radio>
            <v-radio :value="false" label="No"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection title="Were you taken by ambulance to a hospital?" required>
          <v-radio-group
            @change="
              handleFieldInput(
                INCIDENT_FIELD_ALIASES.INCIDENT_TAKEN_TO_HOSPITAL_BY_AMBULANCE,
                arguments[0]
              )
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_TAKEN_TO_HOSPITAL_BY_AMBULANCE]"
            :rules="['requiredBoolean']"
            row
          >
            <v-radio :value="true" label="Yes"></v-radio>
            <v-radio :value="false" label="No"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME]"
          :rules="['required']"
          label="Name of event or teams competing against"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_DESCRIPTION, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_DESCRIPTION]"
          :rules="['required']"
          label="Describe what happened"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-textarea
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_NOTE, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]"
          label="Note"
          outlined
        />
      </v-col>
    </v-row>
    <v-row
      v-if="!fieldIsHidden(INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_TPA_PROCESSING)"
      class="mt-6"
    >
      <v-col cols="12">
        <div
          class="incident-details-form__checkbox-wrapper incident-details-form__checkbox-wrapper--single"
        >
          <v-checkbox
            @change="
              handleFieldInput(
                INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_TPA_PROCESSING,
                arguments[0] || false
              )
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_TPA_PROCESSING]"
            :input-value="this[INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_TPA_PROCESSING]"
            :rules="['required']"
            label="I authorize the claims administrator, A-G Administrators, LCC, to email information about my USA Ruby Insurance Plan."
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE]"
          :rules="['required']"
          label="Signature of claimant"
        />
      </v-col>
      <v-col md="6" cols="0"></v-col>
      <v-col v-if="!fieldIsHidden(INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES)" cols="12" md="6">
        <FilePicker
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES]"
          :loading="loadingFiles"
          fileSize="100"
          accept=".jpeg, .jpg, .png, .avchd, .avi, .flv, .mkv, .mov, .mp4, .wmv, .pdf, .doc, .docx, .xls, .xlsx"
          allowMultiple
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { hiddenFormFields, syncForm } from '@/mixins';
import {
  bodyParts,
  bodySides,
  eventTypes,
  injuryContacts,
  injuryContactTypes,
  injurySeverityTypes,
  injuryTypes,
  membershipTypes,
  playingSurfaces,
} from '@/utils/options';
import { getIncidentDetailsFormProps } from '@/utils/helpers/app/claims';
import FormSection from '@/components/FormSection';
import PhDatepicker from '@/components/pickers/PhDatepicker';
import PhTimepicker from '@/components/pickers/PhTimepicker';
import FilePicker from '@/components/HOC/FilePicker';

export default {
  name: 'IncidentDetails',
  mixins: [hiddenFormFields, syncForm],
  components: {
    FormSection,
    PhDatepicker,
    PhTimepicker,
    FilePicker,
  },
  props: {
    ...getIncidentDetailsFormProps(),
  },
  data() {
    return {
      selectOptions: {
        bodyParts,
        bodySides,
        eventTypes,
        injuryContacts,
        injuryContactTypes,
        injurySeverityTypes,
        injuryTypes,
        membershipTypes,
        playingSurfaces,
      },
    };
  },
  methods: {
    isOther(val) {
      return val === 'OTHER';
    },
  },
};
</script>

<style lang="scss">
.incident-details-form__checkbox-wrapper {
  &--single {
    max-width: 550px;
  }
}
</style>
