<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]"
          :rules="['required']"
          label="First name"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_MIDDLE_INITIAL, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_MIDDLE_INITIAL]"
          label="Middle initial"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]"
          :rules="['required']"
          label="Last name"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_MEMBER_ID, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_MEMBER_ID]"
          :rules="['uid']"
          type="text"
          label="Member ID"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <PhDatepicker
          fullWidth
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB]"
          :hide-details="false"
          placeholder="DOB"
          required
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER, arguments[0])"
          :items="selectOptions.genders"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER]"
          :rules="['required']"
          placeholder="Gender"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL]"
          :rules="['required', 'email']"
          type="email"
          label="Email"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET]"
          :rules="['required']"
          label="Street"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          @input="
            val => {
              this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY] &&
                handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE, null);
              handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY, val);
            }
          "
          :items="countriesOptions"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]"
          :rules="['required']"
          :placeholder="$t('fields.country')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :disabled="!this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]"
          :items="statesOptions[this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]]"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE]"
          :rules="['required']"
          :placeholder="$t('fields.state')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY]"
          :rules="['required']"
          label="City"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]"
          :rules="['required']"
          label="ZIP"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="If minor" darkTitle>
          <v-text-field
            autocomplete="new-password"
            @input="
              handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_FATHERS_FULL_NAME, arguments[0])
            "
            :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_FATHERS_FULL_NAME]"
            label="Father's full name"
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-end">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_MOTHERS_FULL_NAME, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_MOTHERS_FULL_NAME]"
          label="Mother's full name"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Are you" darkTitle>
          <v-select
            @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ROLE_IN_ORG, arguments[0])"
            :items="selectOptions.organizationRoles"
            :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ROLE_IN_ORG]"
            :rules="['required']"
            placeholder="Select role"
          />
        </FormSection>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm, withCountryStateOptions } from '@/mixins';
import { genders, organizationRoles } from '@/utils/options';
import { getClaimantInfoFormProps } from '@/utils/helpers/app/claims';
import FormSection from '@/components/FormSection';
import PhDatepicker from '@/components/pickers/PhDatepicker';

export default {
  name: 'ClaimantInfo',
  mixins: [syncForm, withCountryStateOptions],
  components: {
    FormSection,
    PhDatepicker,
  },
  props: {
    ...getClaimantInfoFormProps(),
  },
  data() {
    return {
      selectOptions: {
        genders,
        organizationRoles,
      },
    };
  },
};
</script>
