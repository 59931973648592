<template>
  <div class="file-picker">
    <transition name="fade" appear>
      <div v-if="loading" class="file-picker__preloader-wrapper">
        <v-progress-circular indeterminate color="accent" />
      </div>
    </transition>
    <div class="file-picker__file" v-if="value && value.name && !allowMultiple">
      <div class="file-picker__file-icon">
        <SvgIcon :icon="'file/' + getExtensionIcon(value)" />
      </div>
      <div class="file-picker__file-name">
        {{ value.name }}
        <SvgIcon @click="clearFile()" tag="div" class="file-picker__file-close" icon="cross" />
      </div>
    </div>
    <template v-if="value && allowMultiple">
      <div class="file-picker__file" v-for="(file, i) in value" :key="i">
        <div class="file-picker__file-icon">
          <SvgIcon :icon="'file/' + getExtensionIcon(file)" />
        </div>
        <div class="file-picker__file-name">
          {{ file.name || file.filename }}
          <SvgIcon @click="clearFile(i)" tag="div" class="file-picker__file-close" icon="cross" />
        </div>
      </div>
    </template>
    <div
      class="file-picker-bg-area"
      :class="{ 'file-picker-active': isActive, 'file-picker-error': hasMessages }"
      @dragover="setActive"
      @dragleave="cancelActive"
      @drop="fileAdded"
    >
      <div class="file-picker-icon-holder">
        <slot name="icon">
          <SvgIcon icon="cloud" />
        </slot>
      </div>
      <input
        :id="id"
        class="file-picker-inputfile"
        type="file"
        :name="id"
        :accept="accept"
        :multiple="allowMultiple"
        @change="fileAdded"
      />
      <div class="file-picker-text">
        Drag files here or
        <label class="file-picker-label" :for="id">
          <slot name="label"> choose file </slot>
        </label>
      </div>
    </div>
    <InputDetails :show="hasMessages" :messages="errorBucket" />
  </div>
</template>

<script>
import validatable from 'vuetify/lib/mixins/validatable';
import InputDetails from '@/components/forms/InputDetails';

export default {
  name: 'FilePicker',
  components: {
    InputDetails,
  },
  mixins: [validatable],
  props: {
    id: {
      default: () => 'file-picker',
    },
    accept: {
      type: String,
      default: '*/*',
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    value: {
      default: () => null,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    requiresTypeCheck() {
      return this.accept !== '*/*';
    },
    acceptedTypes() {
      return this.accept.split(',');
    },
  },
  methods: {
    getExtensionIcon(payload) {
      if (!payload) {
        return false;
      }
      const name = payload.name || payload.filename;
      const exts = ['pdf', 'png', 'jpg', 'svg'];
      const ext = name.split('.').pop();
      return exts.includes(ext) ? ext : 'file';
    },
    clearFile(idx = -1) {
      if (idx < 0) {
        this.$emit('input', null);
      } else {
        this.$emit(
          'input',
          this.value.filter((el, i) => i !== idx)
        );
      }
    },
    cancelHandlers(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    setActive(e) {
      this.isActive = true;
      this.cancelHandlers(e);
    },
    cancelActive(e) {
      this.isActive = false;
      this.cancelHandlers(e);
    },
    fileAdded(e) {
      this.isActive = false;
      this.cancelHandlers(e);
      const wasDropped = e.dataTransfer;
      const files = wasDropped ? e.dataTransfer.files : e.target.files;
      if (wasDropped && !this.allowMultiple && files.length > 1)
        throw new Error('FILE-PICKER: Multiple Files are not allowed');
      if (wasDropped && this.requiresTypeCheck) {
        for (let i = 0; i < files.length; i + 1) {
          if (this.acceptedTypes.indexOf(files[i].type) === -1)
            throw new Error('FILE-PICKER: File type not allowed');
        }
      }
      this.$emit(
        'input',
        this.allowMultiple ? [...(this.value || []), ...Array.from(files)] : files[0]
      );
    },
  },
};
</script>

<style lang="scss">
.file-picker {
  display: flex;
  flex-direction: column;
  padding: 7px 0;
  position: relative;
  .file-picker-bg-area {
    margin-bottom: 8px;
    min-height: 187px;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(216, 216, 216, 0.0001);
    border: 1px dashed #bcc2ca;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .file-picker-inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
  }

  .file-picker-label {
    cursor: pointer;
    text-align: center;
    font-size: 0.9rem;
  }
  .file-picker-active {
    background-color: #d7dbdd;
    outline-color: #f2f3f4;
  }
  .file-picker-error {
    border-color: #ff426b;
  }
  .file-picker-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.4px;
    color: #8b92a8;
  }
  .file-picker-label {
    color: #8f302c;
    padding-bottom: 3px;
    border-bottom: 1px dashed #8f302c;
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.7;
    }
  }
  .file-picker-icon-holder {
    margin-bottom: 8px;
  }
  .file-picker__file {
    display: flex;
    margin-bottom: 25px;
  }
  .file-picker__file-wrapper {
    display: flex;
  }
  .file-picker__file-icon {
    margin-right: 10px;
  }
  .file-picker__file-name {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: #000000;
    position: relative;
  }
  .file-picker__file-close {
    cursor: pointer;
    position: absolute;
    top: -4px;
    right: -14px;
  }
  .file-picker__preloader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba($background-lightest, 0.8);
    z-index: 1;
  }
}
</style>
