import FilePicker from '@/components/FilePicker';
import getDefaultValidationProps from '@/utils/helpers/vuetify/getDefaultValidationProps';

export default (component => ({
  functional: true,
  name: 'FilePicker',
  render(h, context) {
    // FILE SIZE RULE
    const computedProps = getDefaultValidationProps(context);
    if (context.props.fileSize) {
      if (!computedProps.rules) computedProps.rules = [];
      computedProps.rules.push(v => {
        if (!v) return !v;
        const size = context.props.fileSize * 1000 * 1024;
        if (v instanceof File) {
          return v.size < size || `File size shouln't be more than ${context.props.fileSize}mb`;
        }
        if (Array.isArray(v)) {
          return (
            !v
              .slice()
              .map(el => el.size < size)
              .includes(false) || `File size shouln't be more than ${context.props.fileSize}mb`
          );
        }
        return false;
      });
    }

    return h(
      component,
      {
        ...context.data,
        props: {
          ...computedProps,
        },
      },
      context.children
    );
  },
}))(FilePicker);
